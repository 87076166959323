import React, { useState } from 'react';
import { startsWith } from 'lodash';
import { Link, graphql, navigate } from 'gatsby';
import { useForm, Controller } from 'react-hook-form';
import countries from 'countries-list';
import format from 'date-fns/format';
import parse from 'date-fns/parse';
import { es } from 'date-fns/locale';
import addToMailchimp from 'gatsby-plugin-mailchimp';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

import Layout from '../../../components/Layout';
import Dropdown from '../../../components/Dropdown';

import IconWhatsApp from '../../../assets/images/svg/whatsapp.inline.svg';

import '../../../assets/styles/pages/apply-form-page.scss';

const countryCodes = Object.keys(countries.countries);
const countryNames = countryCodes.map((code) => countries.countries[code].name);
const countryNamesOptions = countryNames.sort().map((country) => ({
  value: country,
  label: country,
}));

const FieldValidationErrorMsg = ({ field }) =>
  field ? <div className="error-val">{field.message}</div> : null;

const ApplyForm = ({ data }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setError] = useState(null);
  const { register, control, handleSubmit, errors, watch } = useForm();
  const selectedCity = watch('cityToStudy');
  const [phoneNumberValue, setPhoneNumberValue] = useState('');
  const [isPhoneValid, setIsPhoneValid] = useState(false);
  const [isPhoneTouched, setIsPhoneTouched] = useState(false);

  const allPrismicCourses = data.allPrismicCourses.edges;
  const prismicSpanishApplyForm = data.prismicSpanishApplyForm.data;
  console.log('prismicSpanishApplyForm: ', prismicSpanishApplyForm);

  const getCourseDates = () => {
    const isSevilla = selectedCity && selectedCity.toLowerCase() === 'sevilla';
    return allPrismicCourses
      .filter(({ node: { data } }) =>
        isSevilla
          ? data.name.text.includes(selectedCity.toUpperCase())
          : !data.name.text.includes('SEVILLA')
      )
      .map(({ node: { data } }) => ({
        value: format(new Date(data.from), 'MMMM do YYY'),
        label: format(new Date(data.from), 'd MMMM YYY', { locale: es }),
      }));
  };

  const validatePhoneNumber = (value) => {
    const minDigits = 10;
    const maxDigits = 15;

    const phoneRegex = new RegExp(`^\\+\\d{${minDigits},${maxDigits}}$`);

    return (
      phoneRegex.test(`+${value}`) ||
      'Wrong phone number format. Please enter a valid phone number. +xxxxxxxxxxx'
    );
  };

  const onSubmit = async (data) => {
    // Enable loading
    setIsLoading(true);

    try {
      const MailchimpData = {
        FNAME: data.firstName,
        LNAME: data.lastName,
        MMERGE4: `+${phoneNumberValue}`, // Phone
        MMERGE7: data.courseLevel.value, // Level
        MMERGE9: format(parse(data.courseDate.value, 'MMMM do yyyy', new Date()), 'MM/dd/yyyy'), // Term start
        MMERGE10: data.userUnemployed ? 'Si' : 'No', // Unemployed
        MMERGE8: format(
          new Date(data.userDobYYYY, data.userDobMM - 1, data.userDobDD),
          'MM/dd/yyyy'
        ), // DOB
        MMERGE11: data.promotionalCode, // Promo code
        MMERGE5: data.userNationality.value, // Nationality
        MMERGE3: new Date().toISOString().split('T')[0], // contact date
        MMERGE6: format(parse(data.courseDate.value, 'MMMM do yyyy', new Date()), 'MM/dd/yyyy'), // Course date
        MMERGE12: data.courseSchedule.value, // Timetable
        MMERGE13: data.userFindUs, //How did you hear about us?
        MMERGE14: data.comments, // Comments
      };

      const response = await fetch('/api/crm-es', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ...data,
          userPhone: phoneNumberValue.replace('+', ''),
          userFromGoogleAds: window?.userFromGoogleAds,
          userFromFacebookAds: window?.userFromFacebookAds,
          userFromGoOverseas: window?.userFromGoOverseas,
          userFromGoAbroad: window?.userFromGoAbroad,
        }),
      });
      const jsonResponse = await response.json();
      if (jsonResponse?.success && !jsonResponse?.data?.error_code) {
        setError(false);
        window.fbq('track', 'SubmitApplication');
        addToMailchimp(
          data.userEmail,
          MailchimpData,
          'https://tefl-iberia.us9.list-manage.com/subscribe/post?u=14228e00bf6efc88dcde365c5&amp;id=6bbf455de3'
          // 'https://tefl-iberia.us9.list-manage.com/subscribe/post?u=14228e00bf6efc88dcde365c5&amp;id=6bbf455de3'
        );
        navigate('/es/apply/gracias');
        // setIsSuccess(true);
      } else {
        throw new Error(jsonResponse.error);
      }
    } catch (error) {
      console.error(error);
      setIsSuccess(false);
      setError(error ? error.message || error : 'Oops, something went wrong');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Layout
      title="Learn to Teach English (TEFL) in Barcelona! Apply Now"
      description="Become the TEFL teacher you want to be by applying for TEFL Iberia's friendly"
    >
      <div className="apply-form-page">
        <section className="c-first-section c-first-section--md">
          <div className="container">
            <h1 className="title" style={{ textTransform: 'none' }}>
              Apúntate rellenando el siguiente formulario, te responderemos lo antes posible.
            </h1>
            <div className="wrapper">
              <div className="group">
                <div className="group__disc">
                  <span>1</span>
                </div>
                <div className="group__text">
                  <p className="text__title">Step 1</p>
                  <p className="text__description">Rellenar el formulario abajo</p>
                </div>
              </div>

              <div className="group">
                <div className="group__disc">
                  <span>2</span>
                </div>
                <div className="group__text">
                  <p className="text__title">Step 2</p>
                  <p className="text__description">Hacer la prueba de nivel oral</p>
                </div>
              </div>

              <div className="group">
                <div className="group__disc">
                  <span>3</span>
                </div>
                <div className="group__text">
                  <p className="text__title">Step 3</p>
                  <p className="text__description">¡Empezar a estudiar!</p>
                </div>
              </div>

              {/* <div className="group">
                <div className="group__disc">
                  <span>4</span>
                </div>
                <div className="group__text">
                  <p className="text__title">Step 4</p>
                  <p className="text__description">Place offer</p>
                </div>
              </div> */}
            </div>
          </div>
        </section>

        <section className="second-section">
          <div className="container">
            <form className="forms" onSubmit={handleSubmit(onSubmit)}>
              <div className="form">
                <h1 className="c-title-22 form__title">El curso</h1>

                {/* <h3 className="form__subtitle form__extension-course">¿Dónde quieres estudiar?*</h3> */}
                {/* <div>
                  <div className="form__radio">
                    <input
                      id="barcelona-radio-1"
                      type="radio"
                      value="barcelona"
                      name="cityToStudy"
                      ref={register({
                        required: 'This field is required.',
                      })}
                    />
                    <label htmlFor="barcelona-radio-1">
                      <span className="label">Barcelona</span>
                    </label>
                  </div>

                  <div className="form__radio">
                    <input
                      id="sevilla-radio-2"
                      type="radio"
                      value="sevilla"
                      name="cityToStudy"
                      ref={register({
                        required: 'This field is required.',
                      })}
                    />
                    <label htmlFor="sevilla-radio-2">
                      <span className="label">Sevilla</span>
                    </label>
                  </div>
                  <FieldValidationErrorMsg field={errors.userUnemployed} />
                </div> */}

                <h3 className="form__subtitle start-date">
                  ¿Cuándo quieres empezar a estudiar inglés?*
                </h3>
                <Controller
                  name="courseDate"
                  render={({ ref, ...props }) => (
                    <Dropdown
                      inputRef={ref}
                      options={getCourseDates()}
                      placeholder="Elige"
                      {...props}
                    />
                  )}
                  control={control}
                  defaultValue={null}
                  rules={{
                    required: 'This field is required.',
                  }}
                />
                <FieldValidationErrorMsg field={errors.courseDate} />

                <h3 className="form__subtitle start-date form__extension-course">
                  ¿Qué nivel tienes? (Si no lo sabes, no te preocupes, haremos una prueba rápida de
                  tu nivel para ponerte en el mejor grupo para ti) *
                </h3>
                <Controller
                  name="courseLevel"
                  render={({ ref, ...props }) => (
                    <Dropdown
                      inputRef={ref}
                      options={prismicSpanishApplyForm.level.map((item) => ({
                        label: item.label.text,
                        value: item.value.text,
                      }))}
                      placeholder="Elige"
                      {...props}
                    />
                  )}
                  control={control}
                  defaultValue={null}
                  rules={{
                    required: 'This field is required.',
                  }}
                />
                <FieldValidationErrorMsg field={errors.courseLevel} />

                <h3 className="form__subtitle start-date form__extension-course">
                  ¿Qué horario quieres hacer?*
                </h3>
                <Controller
                  name="courseSchedule"
                  render={({ ref, ...props }) => (
                    <Dropdown
                      inputRef={ref}
                      options={prismicSpanishApplyForm.schedule
                        .filter((item) => {
                          console.log('item: ', item);
                          if (item.location === 'common' || !selectedCity) return true;
                          return item.location?.toLowerCase() === selectedCity?.toLowerCase();
                        })
                        .map((item) => ({
                          label: item.label.text,
                          value: item.value.text,
                        }))}
                      placeholder="Elige"
                      {...props}
                    />
                  )}
                  control={control}
                  defaultValue={null}
                  rules={{
                    required: 'This field is required.',
                  }}
                />
                <FieldValidationErrorMsg field={errors.courseSchedule} />
              </div>

              <div className="form">
                <h1 className="c-title-22 form__title">Sobre ti</h1>
                <label className="form__first-name">
                  <p className="c-text-14 text-under">Primer nombre*</p>
                  <input
                    className="input__first-name"
                    type="text"
                    placeholder="John"
                    name="firstName"
                    ref={register({
                      required: 'This field is required.',
                    })}
                  />
                  <FieldValidationErrorMsg field={errors.firstName} />
                </label>
                <label className="form__last-name">
                  <p className="c-text-14 text-under">Apellidos*</p>
                  <input
                    className="input__last-name"
                    type="text"
                    placeholder="Smith"
                    name="lastName"
                    ref={register({
                      required: 'This field is required.',
                    })}
                  />
                  <FieldValidationErrorMsg field={errors.lastName} />
                </label>
                <label className="form__first-name">
                  <p className="c-text-14 text-under">Correo electrónico*</p>
                  <input
                    className="input__first-name"
                    type="email"
                    placeholder="johnsmith@gmail.com"
                    name="userEmail"
                    ref={register({
                      required: 'This field is required.',
                    })}
                  />
                  <FieldValidationErrorMsg field={errors.userEmail} />
                </label>
                <label className="form__last-name phone-number">
                  <p className="c-text-14 text-under">Número de teléfono</p>
                  {/* <input type="tel" name="userPhoneType" className="begin" placeholder="+34" /> */}
                  <PhoneInput
                    className="input__last-name"
                    placeholder="+XX XXX XXX XXX"
                    value={phoneNumberValue}
                    onChange={(value) => setPhoneNumberValue(value)}
                    onBlur={() => setIsPhoneTouched(true)}
                    isValid={(inputNumber, country, countries) => {
                      return countries.some((country) => {
                        const checkValidity =
                          startsWith(inputNumber, country.dialCode) ||
                          startsWith(country.dialCode, inputNumber);

                        setIsPhoneValid(checkValidity);
                        return checkValidity;
                      });
                    }}
                  />
                  {isPhoneTouched &&
                    !isPhoneValid &&
                    validatePhoneNumber(phoneNumberValue) !== true &&
                    validatePhoneNumber(phoneNumberValue).length > 0 && (
                      <div className="error-val">{validatePhoneNumber(phoneNumberValue)}</div>
                    )}
                </label>

                <h3 className="form__subtitle start-date">Nacionalidad*</h3>
                <Controller
                  name="userNationality"
                  render={({ ref, ...props }) => (
                    <Dropdown
                      inputRef={ref}
                      options={countryNamesOptions}
                      placeholder="Elige"
                      isSearchable
                      {...props}
                    />
                  )}
                  control={control}
                  defaultValue={null}
                  rules={{
                    required: 'This field is required.',
                  }}
                />
                <FieldValidationErrorMsg field={errors.userNationality} />

                <div className="date">
                  <h3 className="form__subtitle">Fecha de nacimiento*</h3>
                  <div className="date__wrapper">
                    <input
                      type="number"
                      placeholder="01"
                      className="input__date input__date--dd"
                      name="userDobDD"
                      ref={register({
                        required: 'This field is required.',
                      })}
                    />
                    <span className="text-fw-600">DD</span>
                  </div>
                  <div className="date__wrapper">
                    <input
                      type="number"
                      placeholder="02"
                      className="input__date input__date--mm"
                      name="userDobMM"
                      ref={register({
                        required: 'This field is required.',
                      })}
                    />
                    <span className="text-fw-600">MM</span>
                  </div>
                  <div className="date__wrapper">
                    <input
                      type="number"
                      placeholder="1987"
                      className="input__date input__date--yyyy"
                      name="userDobYYYY"
                      ref={register({
                        required: 'This field is required.',
                      })}
                    />
                    <span className="text-fw-600">AAAA</span>
                  </div>
                  <FieldValidationErrorMsg
                    field={errors.userDobDD || errors.userDobMM || errors.userDobYYYY}
                  />
                </div>

                <h3 className="form__subtitle form__extension-course">
                  ¿Estás desempleado/a o en el ERTE?*
                </h3>
                <div>
                  <div className="form__radio">
                    <input
                      id="english-speaker-radio-1"
                      type="radio"
                      value="Yes"
                      name="userUnemployed"
                      ref={register({
                        required: 'This field is required.',
                      })}
                    />
                    <label htmlFor="english-speaker-radio-1">
                      <span className="label">Si</span>
                    </label>
                  </div>

                  <div className="form__radio">
                    <input
                      id="english-speaker-radio-2"
                      type="radio"
                      value="No"
                      name="userUnemployed"
                      ref={register({
                        required: 'This field is required.',
                      })}
                    />
                    <label htmlFor="english-speaker-radio-2">
                      <span className="label">No</span>
                    </label>
                  </div>
                  <FieldValidationErrorMsg field={errors.userUnemployed} />
                </div>

                <h3 className="form__subtitle">¿Cómo nos encontraste?</h3>
                <div>
                  <div className="form__radio">
                    <input
                      id="how-find-us-radio-1"
                      type="radio"
                      value="Google"
                      name="userFindUs"
                      ref={register}
                    />
                    <label htmlFor="how-find-us-radio-1">
                      <span className="label">Búsqueda en Google</span>
                    </label>
                  </div>
                  <div className="form__radio">
                    <input
                      id="how-find-us-radio-2"
                      type="radio"
                      value="Facebook"
                      name="userFindUs"
                      ref={register}
                    />
                    <label htmlFor="how-find-us-radio-2">
                      <span className="label">Facebook</span>
                    </label>
                  </div>
                  <div className="form__radio">
                    <input
                      id="how-find-us-radio-3"
                      type="radio"
                      value="Instagram"
                      name="userFindUs"
                      ref={register}
                    />
                    <label htmlFor="how-find-us-radio-3">
                      <span className="label">Instagram</span>
                    </label>
                  </div>
                  <div className="form__radio">
                    <input
                      id="how-find-us-radio-4"
                      type="radio"
                      value="Emagister"
                      name="userFindUs"
                      ref={register}
                    />
                    <label htmlFor="how-find-us-radio-4">
                      <span className="label">Emagister</span>
                    </label>
                  </div>
                  <div className="form__radio">
                    <input
                      id="how-find-us-radio-5"
                      type="radio"
                      value="Poster"
                      name="userFindUs"
                      ref={register}
                    />
                    <label htmlFor="how-find-us-radio-5">
                      <span className="label">Poster / folleto</span>
                    </label>
                  </div>
                  <div className="form__radio">
                    <input
                      id="how-find-us-radio-6"
                      type="radio"
                      value="Friend"
                      name="userFindUs"
                      ref={register}
                    />
                    <label htmlFor="how-find-us-radio-6">
                      <span className="label">Un amigo</span>
                    </label>
                  </div>
                  <div className="form__radio">
                    <input
                      id="how-find-us-radio-7"
                      type="radio"
                      value="Other"
                      name="userFindUs"
                      ref={register}
                    />
                    <label htmlFor="how-find-us-radio-7">
                      <span className="label">Other</span>
                    </label>
                  </div>
                  <FieldValidationErrorMsg field={errors.userNative} />
                </div>

                <label className="form__first-name promotional-code">
                  <p className="c-text-14 text-under">Código promocional</p>
                  <input
                    className="input__first-name"
                    type="text"
                    placeholder="Código"
                    name="promotionalCode"
                    ref={register}
                  />
                </label>

                <label className="form__textarea">
                  <p className="c-text-14 text-under">Comentarios / preguntas</p>
                  <textarea
                    className="input__textarea input__textarea--sm"
                    placeholder="Su mensaje"
                    name="comments"
                    ref={register}
                  />
                </label>

                <h3 className="form__subtitle">Términos y condiciones*</h3>
                <div className="form__checkbox terms">
                  <input
                    id="agree-checkbox-1"
                    type="checkbox"
                    value={true}
                    name="agreeRules"
                    ref={register({ required: 'This field is required.' })}
                  />
                  <label htmlFor="agree-checkbox-1">
                    <span className="label">
                      Acepto los{' '}
                      <Link to="/apply/terms" className="red-link">
                        {' '}
                        términos y condiciones
                      </Link>{' '}
                      y{' '}
                      <Link to="/web/privacy" className="red-link">
                        la política de privacidad.
                      </Link>
                    </span>
                  </label>
                  <FieldValidationErrorMsg field={errors.agreeRules} />
                </div>
              </div>

              <button
                type="submit"
                className="c-btn c-btn--red"
                disabled={
                  Object.keys(errors).length > 0 ||
                  !isPhoneValid ||
                  validatePhoneNumber(phoneNumberValue) !== true
                }
              >
                {isLoading ? <i className="spinner" /> : 'Enviar solicitud'}
              </button>

              {isSuccess && (
                <div className="success-msg">
                  <span> Your application has been successfully submitted!</span>
                </div>
              )}

              {isError && (
                <div className="error-msg">
                  <span>{isError}</span>
                </div>
              )}
              {Object.keys(errors).length > 0 && (
                <div className="error-msg">
                  <span>Some fields are missing or contain errors.</span>
                </div>
              )}
            </form>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query {
    allPrismicCourses(
      sort: { order: ASC, fields: data___from }
      filter: {
        data: { show_on_these_pages: { elemMatch: { page: { eq: "classes-de-ingles" } } } }
      }
    ) {
      edges {
        node {
          data {
            show_on_these_pages {
              page
            }
            from(formatString: "D MMMM YYYY")
            to(formatString: "D MMMM YYYY")
            name {
              text
            }
          }
        }
      }
    }

    prismicSpanishApplyForm {
      data {
        level {
          label {
            text
          }
          value {
            text
          }
        }
        schedule {
          label {
            text
          }
          value {
            text
          }
          location
        }
      }
    }
  }
`;

export default ApplyForm;
